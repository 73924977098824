<template>
    <CContainer fluid>
        <CRow>
            <CCol class='col-12'>
                <CCard>
                    <CRow class='mt-3 ml-3 mr-3 justify-content-between'>
                        <router-link :to="'/addPayroll'" tag='button' role='button' block class='btn btn-success mb-1 ml-1'>
                            Bordro Ekle
                        </router-link>
                    </CRow>
                    <CCardHeader>
                        <strong>Bordro Listesi</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable :items="tableData" :fields="fields" tableFilter hover>
                            <template #show_details="{ item }">
                                <td class="py-2" style="display: inline-flex;">
                                    <router-link :to="{ path: '/DetailPayroll/' + item.id }" tag='button' role='button'
                                        block class='btn btn-success mb-1 ml-1'>
                                        Show
                                    </router-link>
                                    <CButton v-if="item.paymentStatus == 'Pending'" class="mb-1 ml-1"
                                        style="margin-left: 10px;" @click='{ { changeStatus(item) } }' block color='dark'>
                                        Pay</CButton>

                                </td>
                            </template>
                        </CDataTable>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </CContainer>
</template>

<script>
import { AppointmentAdministration, TeacherAdministration } from '@/services/api-service'
import VueLoadingButton from 'vue-loading-button'
import { v4 as uuidv4 } from 'uuid';
import router from "@/router";

export default {
    name: 'Payroll',
    Vcomponents: {
        VueLoadingButton,
    },
    mounted() {
        this.loadAppointmentAdministration()
    },
    watch: {
    },
    data() {
        return {
            status: 'Paid',
            tableData: [],
            fields: [
                { key: "id", label: "ID" },
                { key: "name", label: "Teacher Name" },
                { key: "minutes", label: "Minutes" },
                { key: "earnedAmount", label: "Earned Amount" },
                { key: "hours", label: "Total Hours" },
                { key: "hourlyRate", label: "Hourly Rate" },
                { key: "startDate", label: "Date" },
                { key: "paymentStatus", label: "Status" },
                {
                    key: 'show_details',
                    label: 'Action',
                    _style: 'min-width:20%',
                    filter: false,
                    sorter: false,
                },
            ]
        }
    },
    computed: {
    },
    methods: {
        async loadAppointmentAdministration() {
            this.isListLoading = true;
            const result = await TeacherAdministration.listTeacherSalaries()
            console.log(result);
            this.tableData = result.data.teachers;
        },
        showDetail() {
            router.push({ path: '/DetailPayroll/' + 2 })
        },
        async changeStatusRequest(item) {
            const result = await TeacherAdministration.salariesStatusUpdate(item.id, this.status)
            this.loadAppointmentAdministration();
            console.log("teacherPay ", result);
            this.$toast.success('Bordro başarıyla eklendi!');
        },
        changeStatus(item) {
            this.$swal({
                title: 'Durumu değiştirmek istediğinizden emin misiniz?',
                text: ' ',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Onayla',
                cancelButtonText: 'Vazgeç',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const id = parseInt(this.$route.params['id'])
                    try {
                        const result = await TeacherAdministration.salariesStatusUpdate(item.id, this.status)
                        this.loadAppointmentAdministration();
                        console.log("teacherPay ", result);
                        this.$toast.success('Bordro başarıyla eklendi!');
                        if (result?.status == 200) {
                            await this.$swal.fire({
                                title: 'e-posta başarıyla gönderildi!',
                                text: 'İşlem Başarıyla Gerçekleştirildi',
                                icon: 'success',
                                timer: 2000,
                            })
                        } else {
                            await this.$swal.fire({
                                title: result?.data,
                                text: 'İşlem Gerçekleştirilemedi',
                                icon: 'Hata',
                                timer: 2000,
                            })
                        }
                    } catch (e) {
                        this.toasts.push('İşlem Gerçekleştirilemedi', 'Hata')
                    }
                }
            })
        },
    },

}
</script>
